
import { defineComponent } from 'vue';
import { CustomLoading } from '@/BabylonExamples/CustomLoading';
import LoadingScreen from './LoadingScreen.vue';

export default defineComponent({
  name: 'BabylonExamples',
  data(){
    return {
      isLoaded: false,
    };
  },
  mounted(){
    const canvas = document.querySelector("canvas") as HTMLCanvasElement;
    /* const loadingBar = document.getElementById("loadingBar")as HTMLElement;
    const percentLoaded = document.getElementById("percentLoaded")as HTMLElement;
    const loader = document.getElementById("loader")as HTMLElement; */

    new CustomLoading(canvas, this.setLoaded)
  },
  methods: {
    setLoaded() {
      this.isLoaded = true;
    },
  },
  components: { LoadingScreen }
});
