
export default {
  name: "LoadingScreen",
  props: ["isLoaded"],
  watch: {
    isLoaded() {
      setTimeout(() => {
        document
          .getElementsByClassName("loader")[0]
          .setAttribute("style", "display:none");
      }, 1000);
    },
  },
};
